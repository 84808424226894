import { Button } from "@/Components/ui/button";
import { Card, CardContent } from "@/Components/ui/card";
import sampleImage from '@assets/woman-in-gown.png';
import { Link } from "@inertiajs/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";


const EachProduct = ({ product }) => {
    return <Card data-aos="fade-up" className="relative h-[350px] rounded-2xl border-none col-span-1 shadow-none bg-transparent">
        <Link href={route('predesign-products.view', { uuid: product.uuid })} className="hover:text-cyan-600 hover:underline font-semibold">
            <img src={product.previews?.[0]?.url_thumb} className="absolute-center w-[90%] z-[21] h-full object-cover rounded-2xl" />
        </Link>
        <div className="absolute  bg-white z-[20] bottom-[-15%] border shadow-xl shadow-spread-2 h-[200px] w-full rounded-2xl flex flex-col justify-center" />
        <div className="absolute z-[21] w-full bg-white bottom-[-15%] rounded-b-xl">
            <div className="flex flex-col gap-2 w-full h-full px-4 py-2">
                <Link href={route('predesign-products.view', { uuid: product.uuid })} className="hover:text-cyan-600 hover:underline font-semibold">
                    {product?.name}
                </Link>
                <span className="font-semibold text-xs">{product.price_range}</span>
            </div>
        </div>
    </Card>
}
const fetchBestSellers = async () => {
    const { data } = await window.axios.get(route('predesign-products.paginate', { system: 1, page: 1, per_page: 5, order: 'random:desc' }));
    return data?.data || [];
}

export default function BestSellers() {

    const { data } = useQuery({
        queryKey: ['best-sellers'],
        queryFn: () => fetchBestSellers()
    })

    return <div className="relative justify-start items-center min-h-[70vh] h-full mt-8 flex flex-col gap-4">
        <div data-aos="fade-up" className="max-w-7xl w-full text-primary flex gap-2 pt-8 mb-8 items-center flex-col">
            <h1 className="md:text-5xl text-3xl font-semibold">Best Sellers</h1>
            <h4 className="md:text-3xl text-md font-semibold">Predesign Products</h4>
        </div>
        <div className="max-w-7xl w-full z-20 grid md:grid-cols-5 grid-cols-2  gap-x-4 md:gap-y-4 gap-y-20">
            {data?.map(item => <EachProduct product={item} key={item.id} />)}
        </div>
        <div data-aos="fade-up" className="md:absolute md:bottom-4 z-10 w-full rounded-2xl h-[200px] md:bg-white bg-transparent shadow-spread-2 md:shadow-xl shadow-none">
            <div className="w-full h-full flex flex-col justify-end pb-4 items-center">
                <Link href={route('home.predesign-products', { category: "all" })} className="text-primary hover:underline hover:text-cyan-600 font-semibold px-8 py-2 rounded-2xl">View All</Link>
            </div>
        </div>
    </div>
}
